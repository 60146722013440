<template>
  <div>
    <!-- Komponen TopNav -->
    <TopNav />

    <!-- Container untuk halaman settings -->
    <div class="settings mtb15">
      <div class="container-fluid">
        <div class="row">
          <!-- Komponen Menu -->
          <Menu />

          <!-- Konten utama -->
          <div class="col-md-12 col-lg-9">
            <div class="tab-content" id="v-pills-tabContent">
              <!-- Tab Pane untuk halaman Hapus Akun -->
              <div
                class="tab-pane fade show active"
                id="settings-profile"
                role="tabpanel"
                aria-labelledby="settings-profile-tab"
              >
                <!-- Card untuk form Hapus Akun -->
                <div class="card">
                  <div class="card-body">
                    <h5 class="card-title">Hapus Akun</h5>

                    <!-- Bagian form Hapus Akun -->
                    <div class="settings-profile">
                      <form @submit.prevent="handleDeleteAccount">
                        <!-- Form row dengan grid layout -->
                        <div class="form-row mt-4">
                          <!-- Kolom untuk input Kode -->
                          <div class="col-md-4 mr-md-4 px-4">
                            <div class="row">
                              <p class="confirm_l">
                                Untuk mengkonfirmasi ini, ketikkan "DELETE"
                              </p>
                              <input
                                style="color: #2a2e39"
                                type="text"
                                class="form-control mt-2"
                                v-model="confirmInput"
                                @input="resInvalid = ''"
                              />
                              <small
                                style="color: red; padding-top: 4px"
                                v-if="resInvalid"
                                >{{ resInvalid }}</small
                              >
                            </div>
                            <div class="row mt-4">
                              <textarea
                                style="color: #2a2e39"
                                class="form-control mt-2"
                                v-model="deleteReason"
                                placeholder="Alasan menghapus akun"
                                required
                              ></textarea>
                            </div>

                            <div class="row">
                              <!-- Tombol Hapus -->
                              <input
                                type="submit"
                                value="Hapus"
                                class="btn btn-danger"
                              />
                            </div>
                          </div>

                          <!-- Kolom untuk penjelasan Google Authenticator -->
                          <div class="col-md-7 mt-4 mt-md-0">
                            <p class="text-white">Catatan:</p>
                            <p class="m-0 text-white">
                              Menghapus akun Anda akan menghapus semua informasi
                              Anda dari basis data kami. Tindakan ini tidak
                              dapat dibatalkan.
                            </p>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Akhir Tab Pane -->
            </div>
          </div>
          <!-- Akhir Konten Utama -->
        </div>
      </div>
    </div>
    <!-- Akhir Container untuk halaman settings -->

    <!-- Komponen Footer -->
    <Footer />
  </div>
</template>

<script>
// modules
import Cookies from "js-cookie";
import { useToast } from "vue-toastification";

import thestore from "@/applibs/thestore.js";

// components
import TopNav from "@/components/global/TopNav.vue";
import Footer from "@/components/global/Footer.vue";
import Menu from "@/components/Settings/Menu.vue";

const toast = useToast();

export default {
  components: {
    TopNav,
    Footer,
    Menu,
  },
  data() {
    return {
      token: Cookies.get("jwt"),
      confirmInput: "",
      deleteReason: "",
      resInvalid: "",
    };
  },
  mounted() {
    const isLogin = Cookies.get("jwt") ? true : false;
    if (!isLogin) {
      this.$router.push("/");
    }
  },
  methods: {
    handleDeleteAccount() {
      const userInput = this.confirmInput.toUpperCase();
      if (userInput === "DELETE") {
        // console.log("User mengonfirmasi untuk menghapus akun.");
        this.deleteAccount();
      } else {
        this.resInvalid =
          "Konfirmasi tidak valid. Ketikkan 'DELETE' untuk menghapus.";
      }
    },
    async deleteAccount() {
      // console.log("Menghapus akun...");
      // alert("Akun berhasil di hapus");
      const res = await thestore.postDeleteAccount(
        this.token,
        this.deleteReason
      );

      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          this.logoutAccount();
        }, 3000);
      } else {
        toast.warning(res.msg);
      }
      // console.log("delete: ", res);
    },
    logoutAccount() {
      Cookies.remove("jwt");
      Cookies.remove("usid");
      window.location.href = "/";
    },
  },
};
</script>

<style scoped>
.confirm_l {
  color: rgb(175, 175, 175);
  margin-bottom: -5px;
}
</style>
