// node_modules
// import axios from "axios";

// applibs

var utama = {
  baseApi: process.env.VUE_APP_BASE_API,
  baseWs: process.env.VUE_APP_WS,
  baseSync: process.env.VUE_APP_SYNC,
  baseEvm: process.env.VUE_APP_EVM,
  basePg: process.env.VUE_APP_PG,

  metamapClientId: process.env.VUE_APP_METAMAP_CLIENT_ID,
  metamapFlowId: process.env.VUE_APP_METAMAP_FLOW_ID,

  defaultMarketPair: process.env.VUE_APP_DEFAULT_MARKET_PAIR,

  openModal(elId) {
    // modal element
    var el = document.getElementById(elId);
    el.classList.add("show");
    el.style.display = "block";

    // create element backdrop
    var elemDiv = document.createElement("div");
    elemDiv.classList.add("modal-backdrop", "fade", "show");

    // append kepada app
    var elapp = document.getElementById("app");
    elapp.appendChild(elemDiv);
  },

  closeModal(elId) {
    // hide modal
    var el = document.getElementById(elId);
    el.classList.remove("show");
    el.style.removeProperty("display");

    // remove backdrop
    var backs = document.getElementsByClassName("modal-backdrop");
    for (let step = 0; step < backs.length; step++) {
      backs[step].remove();
    }
  },

  openDropdown(elId) {
    var elbtn = document.getElementById(elId);
    var menu = document.querySelector(`#${elId} .dropdown-menu`);

    if (elbtn.classList.contains("show")) {
      elbtn.classList.remove("show");
      menu.classList.remove("show");
    } else {
      elbtn.classList.add("show");
      menu.classList.add("show");
    }
  },

  closeDropdown(elId) {
    const elbtn = document.getElementById(elId);
    const menu = document.querySelector(`#${elId} .dropdown-menu`);

    if (menu && menu.classList.contains("show")) {
      elbtn.classList.remove("show");
      menu.classList.remove("show");
    }
  },

  openTabNav(elId, activeClass) {
    var container = document.getElementById(elId);

    // remove dari semua nav link class
    var navLinkClass = container.querySelectorAll(`.nav-link`);
    navLinkClass.forEach((a) => {
      a.classList.remove("active");
    });

    // ambil satu class yang dipilih untuk diaktifkan
    var activeEl = document.querySelector(`#${elId} .${activeClass}-head`);
    activeEl.classList.add("active");

    // tab pane nya dimatikan dulu
    var tabPanes = container.querySelectorAll(`.tab-pane`);
    tabPanes.forEach((a) => {
      a.classList.remove("active");
      a.classList.remove("show");
    });

    // ambil satu class yang dipilih untuk diaktifkan
    var activeTab = document.querySelector(`#${elId} .${activeClass}-body`);
    activeTab.classList.add("active");
    activeTab.classList.add("show");
  },

  toggle(elId) {
    var ele = document.getElementById(elId);

    if (ele.classList.contains("show")) {
      ele.classList.remove("show");
    } else {
      ele.classList.add("show");
    }
  },

  onlyAucExecutorPage(connectedAddress) {
    if (
      connectedAddress.toLowerCase() ==
      process.env.VUE_APP_AUC_EXEC.toLowerCase()
    ) {
      console.log("do nothing");
    } else {
      this.$router.push({
        path: "/",
      });
    }
  },

  shortAddress(theaddress) {
    if (theaddress != null && theaddress != "") {
      return `${theaddress.substr(0, 7)}...${theaddress.substr(-6)}`;
    }
  },

  shortAddressMax(theaddress) {
    if (theaddress != null && theaddress != "") {
      return `${theaddress.substr(0, 5)}...${theaddress.substr(-3)}`;
    }
  },

  shortTxHash(theahash) {
    if (theahash != null && theahash != "") {
      return `${theahash.substr(0, 7)}...${theahash.substr(-6)}`;
    }
  },

  /**
   * Random char https://stackoverflow.com/questions/1349404/generate-random-string-characters-in-javascript
   * @param {*} len
   * @param {*} charSet
   * @returns
   */
  randomString(len, charSet) {
    charSet =
      charSet ||
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var randomString = "";
    for (var i = 0; i < len; i++) {
      var randomPoz = Math.floor(Math.random() * charSet.length);
      randomString += charSet.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  },

  /**
   * Digunakan ketika user ada image atau tidak
   */
  theUserImg(imageUrl) {
    if (imageUrl) {
      return imageUrl;
    } else {
      return "/images/default-user.png";
    }
  },

  functionModalBackdropShow(self) {
    self.modalBackdropMain = true;
    self.modalBackdropFade = true;
    self.modalBackdropShow = true;
  },
  functionModalBackdropHide(self) {
    self.modalBackdropMain = false;
    self.modalBackdropFade = false;
    self.modalBackdropShow = false;
  },
};

export default utama;
