<template>
  <div class="market-trade-sell">
    <div class="row">
      <div class="col pr-1 text-light">
        Fee: <br />
        <small
          >{{
            (parseFloat(marketData.ticker_percent_spread) * 100).numberFormat(2)
          }}
          %</small
        >
      </div>
      <div class="col-8 pl-1 text-light text-right">
        Tersedia: <br />
        <small
          >{{ parseFloat(sellForm.tokenAOwned).numberFormat(4) }}
          {{ simbol_a }}</small
        >
      </div>
    </div>

    <div class="input-group mt-2">
      <!-- ada kesalahan eslint. Kita disable dengan cara seperti ini -->
      <!-- eslint-disable -->
      <input
        v-if="isFormatNumber == false"
        type="text"
        v-model="sellForm.price"
        class="form-control"
        @blur="formatPrice"
        @keyup="totalCalculation"
        @click="handleClick"
        placeholder="Price"
      />
      <input
        class="form-control"
        v-else
        type="text"
        v-model="idrFormat"
        @click="handleClick"
      />
      <!-- eslint-enable -->
      <div class="input-group-append">
        <span class="input-group-text">{{ simbol_b }}</span>
      </div>
    </div>
    <div class="input-group">
      <input
        type="text"
        step="any"
        placeholder="Qty"
        class="form-control"
        v-model="sellForm.qtyA"
        @keyup="totalCalculation"
      />
      <div class="input-group-append">
        <span class="input-group-text">{{ simbol_a }}</span>
      </div>
    </div>

    <ul class="market-trade-list">
      <li><span @click="percentClicked(0.25)" class="percent">25%</span></li>
      <li><span @click="percentClicked(0.5)" class="percent">50%</span></li>
      <li><span @click="percentClicked(0.75)" class="percent">75%</span></li>
      <li><span @click="percentClicked(1)" class="percent">100%</span></li>
    </ul>

    <p>
      Total:
      <span
        >{{ parseFloat(sellForm.qtyB).numberFormat(4) }} {{ simbol_b }}</span
      >
    </p>

    <div class="row">
      <div class="col-12">
        <button class="btn sell" @click="sellBtnClicked">Jual</button>
      </div>
    </div>
  </div>
</template>

<script>
// node_modules
import Cookies from "js-cookie";

// applibs
import thestore from "@/applibs/thestore.js";
import themain from "@/applibs/themain.js";

// components

export default {
  components: {},
  props: {
    marketData: {
      type: Object,
      required: true,
    },
    currencyAObj: {
      type: Object,
    },
    currencyBObj: {
      type: Object,
    },
    handleFormatDisplay: {
      type: Function,
    },
    isFormatNumber: {
      type: Boolean,
    },
  },
  data() {
    return {
      themain,

      market_id: this.$route.params.market_id,
      market_pair: this.$route.params.market_pair,

      token: Cookies.get("jwt"),

      price_buy: 0,

      simbol_a: "",
      simbol_b: "",

      sellForm: {
        price: 0,
        qtyA: 0,
        qtyB: 0,
        tokenAOwned: 0,
      },

      f_buys: [],
      // isFormatNumber: false,
      idrFormat: 0,

      market_fee: 0,

      submitBtnDisabled: false,
    };
  },

  async created() {
    var self = this;

    self.emitter.on("fe-ubalances", async function (e) {
      self.ubalances = e.data;

      // update balance
      self.sellForm.tokenAOwned = e.data[self.simbol_a];
    });

    self.emitter.on("fe-buys", function (e) {
      self.f_buys = e.data.sort((a, b) => {
        return parseFloat(b.market_position) - parseFloat(a.market_position);
      });
      if (self.f_buys && self.f_buys.length > 0) {
        self.price_buy = self.f_buys[0].market_position;
      } else {
        self.price_buy = 0;
      }
    });

    self.emitter.on("fe-obook_clicked", async function (e) {
      self.sellForm.price = parseFloat(e.data.obj.market_position);
      if (e.data.kind == "sell") {
        self.sellForm.qtyA = e.data.obj.market_amount;
      } else {
        var jumlah = 0;
        self.f_buys.forEach((el) => {
          if (
            parseFloat(el.market_position) >=
            parseFloat(e.data.obj.market_position)
          ) {
            jumlah = jumlah + parseFloat(el.market_result_including_fee);
          }
        });
        self.sellForm.qtyA = jumlah; // harus dibatasi segini. Kalau tidak dibatasi, maka akan ada error
        self.handleFormatDisplay(false);
        self.idrFormat = jumlah;
      }
      self.totalCalculation();
    });
  },

  async mounted() {
    var self = this;

    var market_pair_arr = self.market_pair.split("_");
    self.simbol_a = market_pair_arr[0];
    self.simbol_b = market_pair_arr[1];

    // ambil balance user terlebih dahulu (balance symbol b)
    var res = await thestore.getBalances(self.token, 1, 10, "id", "asc", [
      { field: "ccurrency_symbol", op: "equal", value: self.simbol_a },
    ]);

    self.sellForm.tokenAOwned = res.datas[0].saldo_value;
  },

  methods: {
    handleClick() {
      this.handleFormatDisplay(false);
    },
    async sellBtnClicked() {
      var self = this;
      await thestore.postExchange(
        self.token,
        self.market_pair,
        "Limit Sell",
        self.sellForm.price,
        self.sellForm.qtyA,
        false
      );
      self.handleFormatDisplay(false);
    },
    formatPrice() {
      this.handleFormatDisplay(true);
      const formattedNumber = new Intl.NumberFormat().format(
        this.sellForm.price
      );
      this.idrFormat = formattedNumber;

      this.sellForm.price = parseFloat(this.buyForm.price);
    },
    totalCalculation() {
      var self = this;
      self.sellForm["qtyB"] =
        parseFloat(self.sellForm.qtyA) * parseFloat(self.sellForm.price);
    },

    percentClicked(percent) {
      var self = this;

      self.sellForm.qtyA = parseFloat(self.sellForm.tokenAOwned) * percent;
      self.totalCalculation();
    },
  },
};
</script>

<style scoped>
@media only screen and (max-width: 767px) {
  .market-trade-sell {
    border-left: none !important;
  }
}

#dark .market-trade-list li span.percent {
  background: #2a2e39;
  color: #ffffff;
}
.market-trade-list li span.percent {
  background: #eff2f6;
  color: #4a4a4a;
  font-weight: 300;
  padding: 3px 15px;
  border-radius: 5px;
  cursor: pointer;
}
.market-trade-list {
  margin-bottom: 0px;
}
.market-trade button.buy,
.market-trade button.sell {
  margin-top: 4px;
}

.form-control {
  color: #ffffff !important;
}
</style>
